import "./ArrowMoreContent.scss"

import React, { FC } from "react"

export interface ArrowMoreContentProps {
  color?: string;
  colorHover?: string;
  hoverAnimationDuration?: string;
  containerWidth: string;
  containerHeight: string;
  sideWidth: string;
  width: string;
  isAnimated?: boolean;
  isHover?: boolean;
  className?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
  clickHandler: (event) => void;
}

const ArrowMoreContent: FC<ArrowMoreContentProps> = ({
  color,
  colorHover,
  hoverAnimationDuration,
  containerWidth,
  containerHeight,
  sideWidth,
  width,
  direction,
  isAnimated,
  isHover,
  clickHandler,
  className,
}) => {
  const styles = {
    '--arrow-container-width': containerWidth,
    '--arrow-container-height': containerHeight,
    '--color': color || '#000',
    '--color-hover': colorHover,
    '--hover-animation-duration': hoverAnimationDuration ? hoverAnimationDuration : '.35s',
    '--side-width': sideWidth,
    '--size': width,
  } as React.CSSProperties;

  const classNames = () => {
    const defaultDirection = 'down';
    const classNameBase = 'arrow-more-content-wrapper';
    const directionClassName = `${classNameBase}--${direction ? direction : defaultDirection }`;

    const classes = [classNameBase, directionClassName];

    if (isAnimated) {
      classes.push(`${classNameBase}--animated`);
    }

    if (isHover) {
      classes.push(`${classNameBase}--hover`);
    }

    if (clickHandler) {
      classes.push('cursor-click');
    }

    if (className) {
      classes.push(className);
    }
    
    return classes.join(' ');
  };

  return (
    <div
      className={classNames()}
      style={styles}
      onClick={clickHandler}
    >
      <div className="arrow-more-content"></div>
    </div>
  )
}

export default ArrowMoreContent
