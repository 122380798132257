import React, { CSSProperties, FC } from "react";
import { NavProvider } from "../../components/Layout/Nav/NavProvider";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { getCurrentLanguage } from "../../i18n";
import Nut from "../../components/Nut";
import "./index.scss";
import { t } from "i18next";
import ArrowMoreContent from "../../components/ArrowMoreContent";

const ThankYouPage: FC = () => {
  const currentLanguage = getCurrentLanguage();
  const slug = typeof window !== "undefined" ? window.location.pathname : "";

  const titleDelayStyle = (delay: number) => {
    return { animationDelay: `${delay}s` } as CSSProperties;
  };

  return (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeText={t("common.thankYou")}
        alternativeSlug={"thankyou"}
      >
        <SEO
          title={t("thankYou.pageTitle")}
          description={t("thankYou.pageDescription")}
          lang={currentLanguage}
          slug={slug}
        />
        <section className="thank-you">
          <SectionHeader />
          <div className="thank-you__row">
            <div className="thank-you__column">
              <div className="thank-you__infobox">
                <div
                  className={`thank-you__content-success is-visible`}
                >
                  <div className="thank-you__text-section">
                    <span className="thank-you__title">
                      {t("contact.many")}
                    </span>
                  </div>
                  <div
                    className="thank-you__text-section thank-you__text-section--big"
                    style={titleDelayStyle(0.5)}
                  >
                    <span className="thank-you__highlighted">
                      {t("contact.thanks")}
                    </span>
                  </div>

                  <div className={`thank-you__description`} >
                    <ArrowMoreContent
                      color="#fff"
                      className="thank-you__arrow"
                      containerHeight="1.8rem"
                      containerWidth="1.8rem"
                      sideWidth="0.9rem"
                      width=".2rem"
                      direction="right"
                      clickHandler={null}
                    />
                    <span>{t("contact.contactSoon")}</span>
                  </div>
                </div>
              </div>

            </div>
            <div className={`thank-you__column thank-you__column--start is-hidden`}>
            </div>

            <div className={`thank-you__nut-wrapper is-visible`}>
              <Nut rotate />
            </div>
          </div>
        </section>
      </Layout>
    </NavProvider>
  );
};

export default ThankYouPage;
